import { memo } from 'react';
import { StyledFooter } from '../styled';
import FooterLinks from '../FooterLinks/FooterLinks';
import Copyrights from '../Copyrights/Copyrights';

const Footer = memo(() => (
  <StyledFooter>
    <FooterLinks />
    <Copyrights />
  </StyledFooter>
));

export default Footer;
