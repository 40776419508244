import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import { Heading1 } from '../Headings';

const StyledCallbackUrl = styled.p`
  font-size: 14px;
  font-weight: normal;
  color: #000;
  background-color: #f3f3f3;
  border: 1px solid #ebebeb;
  margin: 0px;
  padding: 15px;
  border-radius: 5px;
  line-height: 25px;
`;

const StyledPageHeading = styled(Heading1)`
  background-color: #e2f3ff;
  padding: 15px 30px;
  font-size: 18px;
  margin: 0px;
  font-weight: normal;
  text-transform: capitalize;
`;

const AuthBackground = styled.section`
  background-color: ${({ theme: { colors } }) =>
    colors.authBackgroundColor || `#ffffff`};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const AuthContainer = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
`;

const AuthSliderContainer = styled.section`
  flex: 1;
  background-color: ${({ theme: { colors } }) =>
    colors.primaryColor || `#3049f8`};
  display: flex;
  flex-direction: column;
  padding: 40px;
  min-width: 400px;
  position: relative;
`;

const AuthWelcomeText = styled.h1`
  font-size: 50px;
  font-weight: bold;
  color: #fff;
  margin: 0px;
  padding: 0px;
  z-index: 9;
`;

const AuthTaglineText = styled.h2`
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin: 20px 0px 0px;
  padding: 0px;
  z-index: 9;
`;

const AuthToolsList = styled.ul`
  list-style: circle;
  margin: 0px;
  padding: 0px 0px 0px 20px;
  color: #fff;
  z-index: 9;
`;

const AuthToolsListItem = styled.li`
  margin: 0px;
  padding: 0px;
  z-index: 9;
`;

const AuthToolsText = styled.h3`
  font-size: 20px;
  font-weight: normal;
  color: #fff;
  margin: 20px 0px;
  padding: 0px;
  font-family: monospace;
  z-index: 9;
`;

const AuthToolsImage = styled.img`
  width: 300px;
  position: absolute;
  bottom: 20px;
  left: 20px;
`;

const AuthFormContainer = styled.section`
  flex: 3;
  flex-direction: column;
  display: flex;
  position: relative;
`;

const AuthFormInnerContainer = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AuthLogoContainer = styled.section`
  width: 150px;
`;

const AuthFormBody = styled.section`
  width: 100%;
  max-width: 400px;
`;

const StyledFooter = styled.section`
  border-top: 1px solid #f4f4f4;
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
  align-items: center;
`;

const StyledAuthLinks = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledLink = styled(Link)`
  font-size: 14px;
  font-weight: normal;
  color: ${({ theme: { colors } }) => colors.primaryColor || `#3049f8`};
  text-decoration: none;
  margin: 0px;
  padding: 0px;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledButtonAsLink = styled.button`
  border-width: 0px;
  background-color: transparent;
  cursor: pointer;
  outline: 0;
  font-size: 14px;
  font-weight: normal;
  color: ${({ theme: { colors } }) => colors.primaryColor || `#3049f8`};
  text-decoration: none;
  margin: 0px;
  padding: 0px;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledFormFieldError = styled.p`
  font-size: 13px;
  margin: 0px;
  background-color: #ff4343;
  color: #fff;
  padding: 5px 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const StyledAuthFooterLinkText = styled.p`
  font-size: 14px;
  font-weight: normal;
  color: #000000;
  text-decoration: none;
  margin: 0px;
  padding: 0px;
`;

const StyledPara = styled.p`
  font-size: 14px;
  font-weight: normal;
  color: #000000;
  text-decoration: none;
  margin: 0px;
  padding: 0px;
  line-height: 24px;
  text-align: center;
`;

const StyledTextHighlight = styled.span`
  font-size: 14px;
  font-weight: normal;
  color: ${({ theme: { colors } }) => colors.primaryColor || `#3049f8`};
  text-decoration: none;
  margin: 0px;
  padding: 0px;
`;

const StyledSpan = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-style: italic;
  color: #878787;
  text-decoration: none;
  margin: 8px 0px 0px 0px;
  padding: 0px;
  display: block;
`;

const StyledAppContainer = styled.section`
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 0;
`;

const StyledAside = styled.aside`
  width: auto;
  height: 100%;
  padding: 0px;
  margin: 0px;
  background-color: ${({ theme: { colors } }) =>
    colors.primaryColor || `#3049f8`};
  display: flex;
  flex-direction: column;
  z-index: 9999;
  box-shadow: 0px 0px 20px -5px #000000;
  a {
    color: #ffffff;
    text-decoration: none;
  }
`;

const StyledAsideHeader = styled.section`
  position: relative;
  padding: 8px;
  border-bottom: 1px solid #2941e5;
  display: flex;
  height: 40px;
  column-gap: 8px;
`;

const StyledAsideLogoContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
`;

const StyledAsideTitle = styled.section`
  flex: 1;
  display: flex;
  align-items: center;
`;

const StyledAsideTitleText = styled.h1`
  font-size: 18px;
  margin: 0px;
  padding: 0px;
  color: #ffffff;
  font-weight: normal;
`;

const StyledToggleButton = styled.section`
  width: 24px;
  height: 24px;
  border-radius: 100px;
  background-color: ${({ theme: { colors } }) =>
    colors.primaryColor || `#3049f8`};
  position: absolute;
  top: 50%;
  right: -15px;
  transform: translate(0%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  box-shadow: -5px 0px 10px -3px #000;
  svg {
    font-size: 12px;
  }
  &:hover {
    background-color: #2941e5;
  }
`;

const StyledAsideBody = styled.section`
  flex: 1;
  padding: 8px;
  overflow-y: scroll;
  a {
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    background-color: #2941e5;
    display: block;
    &:hover {
      background-color: #213cf1;
    }
    &.active {
      background-color: #213cf1;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledLinkContainer = styled.section`
  display: grid;
  grid-template-columns: max-content auto;
  margin-bottom: 8px;
  &.last-child {
    margin: 0px;
  }
`;

const StyledLinkIcon = styled.section`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  svg {
    font-size: 18px;
  }
`;

const StyledLinkTextContainer = styled.section`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 8px 60px 8px 8px;
  border-left: 1px solid #233ad7;
`;

const StyledLinkText = styled.span`
  font-size: 14px;
  font-weight: normal;
  color: #ffffff;
  text-decoration: none;
`;

const StyledAsideFooter = styled.section`
  position: relative;
  padding: 8px;
  border-top: 1px solid #2941e5;
  height: 40px;
  a {
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    background-color: #2941e5;
    display: block;
    &:hover {
      background-color: #213cf1;
    }
    &.active {
      background-color: #213cf1;
    }
  }
`;

const StyledMain = styled.main`
  flex: 1;
  overflow-y: scroll;
`;

const StyledHeader = styled.header`
  width: 100%;
  height: 56px;
  margin: 0 auto;
  padding: 0px;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #e2e2e2;
`;

const StyledNavigationContainer = styled.section`
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0px;
  background-color: #e0f2fe;
  display: flex;
  flex-direction: row;
`;

const StyledNavigation = styled.nav`
  flex: 1;
  display: flex;
  align-items: center;
`;

const StyledNavigationList = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

const StyledNavigationListItem = styled.li`
  margin: 0px;
  padding: 0px;
  height: 100%;
`;

const StyledNavigationListItemLink = styled(NavLink)`
  font-size: 14px;
  font-weight: normal;
  color: #005897;
  padding: 12px 24px;
  margin: 0px;
  text-decoration: none;
  display: inline-block;
  &:hover {
    background-color: #b6daff;
  }
  &.active {
    background-color: #b6daff;
  }
`;

const StyledPackageExpiryContainer = styled.section`
  width: auto;
  display: flex;
  background-color: ${({ alertexpiry }) =>
    alertexpiry === 'Yes' ? '#ff4949' : '#4caf50'};
  font-size: 14px;
  font-weight: normal;
  color: #fff;
  padding: 0px 20px;
  flex-direction: column;
  margin: 0px;
  justify-content: center;
  align-items: center;
  row-gap: 3px;
`;

const StyledPackageExpiryHeading = styled.span`
  font-size: 12px;
`;

const StyledPackageExpiryDate = styled.span`
  font-size: 12px;
`;

const StyledContentContainer = styled.section`
  flex: 1;
  background-color: ${({ graybackground }) =>
    graybackground === 'true' ? '#f3f3f3' : '#ffffff'};
  padding: 30px;
`;

const StyledMainAreaContainer = styled.section`
  display: flex;
  flex-direction: row;
  column-gap: 30px;
`;

const StyledGridLayout = styled.section`
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex-wrap: wrap;
`;

const StyledActionItemButtons = styled.section`
  width: fit-content;
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  align-items: center;
  justify-content: center;
`;

const StyledEditItemButton = styled(NavLink)`
  font-size: 14px;
  font-weight: normal;
  color: #0095ff;
  text-decoration: none;
  margin: 0px;
  padding: 0px;
  &:hover {
    color: #0180db;
  }
`;

const StyledFiltersContainer = styled.section`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  margin-bottom: 20px;
`;

const StyledFilters = styled.section`
  flex: 1;
  display: flex;
  column-gap: 15px;
  flex-wrap: wrap;
`;

const StyledFilterContainer = styled.section`
  width: 100%;
  max-width: 300px;
`;

const StyledActionsContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 15px;
`;

const StyledSearchButton = styled.button`
  font-size: 14px;
  font-weight: normal;
  color: #ffffff;
  background-color: #0095ff;
  border: 0px;
  border-radius: 5px;
  margin: 0px;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  outline: 0px;
  cursor: pointer;
  &:hover {
    background-color: #0083e1;
  }
`;

const StyledResetButton = styled.button`
  font-size: 14px;
  font-weight: normal;
  color: #3a3a3a;
  background-color: #e3e3e3;
  border: 0px;
  border-radius: 5px;
  margin: 0px;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  outline: 0px;
  cursor: pointer;
  &:hover {
    background-color: #cdcdcd;
  }
`;

const StyledStatusText = styled.p`
  font-size: 13px;
  color: #959595;
  margin: 10px 0px 0px 0px;
  padding: 0px;
  font-style: italic;
`;

const StyledConnectButton = styled.button`
  font-size: 14px;
  font-weight: normal;
  padding: 0px;
  margin: 0px;
  color: ${({ color }) => (color ? color : '#4caf50')};
  background-color: transparent;
  border: 0px;
  outline: 0px;
  cursor: pointer;
  &:hover {
    color: ${({ hoverColor }) => (hoverColor ? hoverColor : '#328f36')};
  }
`;

const StyledStatusPill = styled.span`
  min-width: 100px;
  width: auto;
  height: auto;
  margin: 0px;
  padding: 10px;
  background-color: #e5e5e5;
  border-radius: 50px;
  display: inline-block;
  text-align: center;
  &.red {
    color: #ffffff;
    background-color: red;
  }
  &.green {
    color: #ffffff;
    background-color: forestgreen;
  }
  &.yellow {
    color: #000000;
    background-color: #ff9800;
  }
  &.blue {
    color: #ffffff;
    background-color: #0095ff;
  }
`;

const StyledPlansOuterContainer = styled.section`
  display: grid;
  justify-content: center;
`;

const StyledPlansContainer = styled.section`
  display: flex;
  flex-direction: row;
  column-gap: 30px;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;

const StyledPlansHeading = styled.h1`
  text-align: center;
  margin: 0px;
  padding: 0px 0px 30px;
  color: #000000;
  font-size: 50px;
`;

const StyledSlabText = styled.h2`
  font-size: 42px;
  text-align: left;
  color: #999999;
  margin: 0px 0px 10px;
`;

export {
  StyledCallbackUrl,
  StyledPageHeading,
  AuthBackground,
  AuthContainer,
  AuthSliderContainer,
  AuthWelcomeText,
  AuthTaglineText,
  AuthToolsList,
  AuthToolsListItem,
  AuthToolsText,
  AuthToolsImage,
  AuthFormContainer,
  AuthFormInnerContainer,
  AuthLogoContainer,
  AuthFormBody,
  StyledFooter,
  StyledAuthLinks,
  StyledLink,
  StyledButtonAsLink,
  StyledFormFieldError,
  StyledAuthFooterLinkText,
  StyledPara,
  StyledTextHighlight,
  StyledAppContainer,
  StyledAside,
  StyledAsideHeader,
  StyledAsideLogoContainer,
  StyledAsideTitle,
  StyledAsideTitleText,
  StyledToggleButton,
  StyledAsideBody,
  StyledLinkContainer,
  StyledLinkIcon,
  StyledLinkTextContainer,
  StyledLinkText,
  StyledAsideFooter,
  StyledMain,
  StyledHeader,
  StyledNavigationContainer,
  StyledNavigation,
  StyledNavigationList,
  StyledNavigationListItem,
  StyledNavigationListItemLink,
  StyledPackageExpiryContainer,
  StyledPackageExpiryHeading,
  StyledPackageExpiryDate,
  StyledContentContainer,
  StyledMainAreaContainer,
  StyledGridLayout,
  StyledActionItemButtons,
  StyledEditItemButton,
  StyledFiltersContainer,
  StyledFilters,
  StyledFilterContainer,
  StyledActionsContainer,
  StyledSearchButton,
  StyledResetButton,
  StyledStatusText,
  StyledConnectButton,
  StyledSpan,
  StyledStatusPill,
  StyledPlansOuterContainer,
  StyledPlansContainer,
  StyledPlansHeading,
  StyledSlabText,
};
