const { protocol, host } = window.location;

const API_CONFIG = {
  API_BASE_URL:
    process.env.NODE_ENV === 'development'
      ? // ? 'https://apis.robo.remitsarathi.com/v1'
        'http://localhost:8080/v1'
      : `${protocol}//apis.${host.replace('www.', '')}/v1`,
  HEADERS: {
    POST: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    GET: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    PUT: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    DELETE: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  },
};

const ROUTE_TYPES = {
  PUBLIC: 'PUBLIC',
  PROTECTED: 'PROTECTED',
  REDIRECT_IF_LOGIN: 'REDIRECT_IF_LOGIN',
};

const LOGIN_TYPES = {
  PASSWORD: 'PASSWORD',
  OTP: 'OTP',
};

const OTP_VERIFICATION_TYPES = {
  REGISTER: 'REGISTER',
  LOGIN: 'LOGIN',
  RESET_PASSWORD: 'RESET_PASSWORD',
  GENERATE_API_CREDENTIALS: 'GENERATE_API_CREDENTIALS',
  UPDATE_API_SETTINGS: 'UPDATE_API_SETTINGS',
  UPDATE_CSC_CREDENTIALS: 'UPDATE_CSC_CREDENTIALS',
};

const RESPONSE_STATUSES = {
  PENDING: 'Pending',
  SUCCESS: 'Success',
  FAILED: 'Failed',
  EXPIRED: 'Expired',
};

const MOBILE_NUMBER_VERIFICATION_STATUS = {
  PENDING: 'Pending',
  VERIFIED: 'Verified',
};

const USER_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

const PLAN_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

const OPERATOR_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export {
  API_CONFIG,
  ROUTE_TYPES,
  LOGIN_TYPES,
  OTP_VERIFICATION_TYPES,
  RESPONSE_STATUSES,
  MOBILE_NUMBER_VERIFICATION_STATUS,
  USER_STATUS,
  PLAN_STATUS,
  OPERATOR_STATUS,
};
