import axios from 'axios';
import {
  getPlatform,
  getDeviceType,
  getOperatingSystem,
  getAuthData,
  clearLocalStorage,
} from '../utils';
import { API_CONFIG, RESPONSE_STATUSES } from '../constants';

const { API_BASE_URL, HEADERS } = API_CONFIG;
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});
const redirectUrl = window.location.href;

axiosInstance.interceptors.request.use(
  (request) => {
    const { isLoggedIn, sessionToken } = getAuthData();
    request.headers['X-Platform'] = getPlatform();
    request.headers['X-Device-Type'] = getDeviceType();
    request.headers['X-Operating-System'] = getOperatingSystem();
    if (isLoggedIn && sessionToken) {
      request.headers['Authorization'] = sessionToken;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data.status === RESPONSE_STATUSES.EXPIRED) {
      clearLocalStorage();
      window.location.href = `/login?redirectUrl=${redirectUrl}&errorMessage=Your session has expired. Please login again.`;
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      clearLocalStorage();
      window.location.href = `/login?redirectUrl=${redirectUrl}&errorMessage=Unauthorized. Please login again.`;
    } else {
      console.error(error);
    }
    return Promise.reject(error);
  }
);

const API = {
  post: (url, data, headers = HEADERS.POST) => {
    return axiosInstance.post(url, data, headers);
  },
  get: (url, headers = HEADERS.GET) => {
    return axiosInstance.get(url, headers);
  },
  put: (url, data, headers = HEADERS.PUT) => {
    return axiosInstance.put(url, data, headers);
  },
  delete: (url, headers = HEADERS.DELETE) => {
    return axiosInstance.delete(url, headers);
  },
};

export default API;
