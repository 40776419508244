import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';
import moment from 'moment';
import { withAppHOC } from '../../hoc';
import { StyledContentContainer } from '../../components/styled';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import API from '../../api';
import { Link } from 'react-router-dom';
import { AgCharts } from 'ag-charts-react';

const StyledWelcomeMessage = styled.h1`
  font-size: 26px;
  font-weight: normal;
  color: #000000;
  margin: 0px 0px 20px 0px;
  padding: 0px;
`;

const StyledPlanExpiryMessage = styled.h2`
  font-size: 22px;
  font-weight: normal;
  color: #000000;
  margin: 0px 0px 20px 0px;
  padding: 0px;
  a {
    color: #433cf2;
    text-decoration: none;
  }
`;

const StyledStatsContainer = styled.section`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  margin-top: 30px;
  margin-bottom: 35px;
`;

const StyledStatsBlock = styled.section`
  width: 100%;
  max-width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 10px 10px 30px -20px #000;
`;

const StyledBlockHeader = styled.section`
  padding: 15px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  font-size: 16px;
  color: #ffffff;
  font-family: verdana;
  font-weight: normal;
  letter-spacing: 1px;
  margin: 0px;
`;

const StyledBlockBody = styled.section`
  padding: 30px 15px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  font-size: 24px;
  color: #ffffff;
`;

const StyledGraphContainer = styled.section`
  width: 100%;
`;

const StyledGraphsContainer = styled.section`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  margin-bottom: 30px;
`;

const Dashboard = () => {
  const readData = async () => {
    try {
      const response = await API.get(`/stats`);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data, isLoading } = useQuery({
    queryKey: ['stats'],
    queryFn: readData,
    cacheTime: 0,
    staleTime: Infinity,
  });

  const userDetails = data?.data?.userDetails;
  const graphData = data?.data?.graphData;

  const fullNameSplit = userDetails?.fullName?.split(' ');

  const billFetchDataList = graphData
    ?.filter(({ requestType }) => requestType === 'FETCH_BILL')
    .map(({ transactionDate, successCounts, pendingCounts, failedCounts }) => ({
      date: moment(transactionDate).format('DD-MM-YYYY'),
      total:
        parseInt(successCounts) +
        parseInt(failedCounts) +
        parseInt(pendingCounts),
      success: parseInt(successCounts),
      pending: parseInt(pendingCounts),
      failed: parseInt(failedCounts),
    }));

  const billPayDataList = graphData
    ?.filter(({ requestType }) => requestType === 'PAY_BILL')
    .map(({ transactionDate, successCounts, pendingCounts, failedCounts }) => ({
      date: moment(transactionDate).format('DD-MM-YYYY'),
      total:
        parseInt(successCounts) +
        parseInt(failedCounts) +
        parseInt(pendingCounts),
      success: parseInt(successCounts),
      pending: parseInt(pendingCounts),
      failed: parseInt(failedCounts),
    }));

  const billFetchData = {
    title: {
      text: 'Bill Fetch Stats',
    },
    subtitle: {
      text: 'Your bill fetch stats',
    },
    data: billFetchDataList,
    series: [
      { type: 'bar', xKey: 'date', yKey: 'total', yName: 'Total Requests' },
      { type: 'bar', xKey: 'date', yKey: 'success', yName: 'Success Requests' },
      { type: 'bar', xKey: 'date', yKey: 'pending', yName: 'Pending Requests' },
      { type: 'bar', xKey: 'date', yKey: 'failed', yName: 'Failed Requests' },
    ],
  };

  const billPayData = {
    title: {
      text: 'Bill Pay Stats',
    },
    subtitle: {
      text: 'Your bill pay stats',
    },
    data: billPayDataList,
    series: [
      { type: 'bar', xKey: 'date', yKey: 'total', yName: 'Total Requests' },
      { type: 'bar', xKey: 'date', yKey: 'success', yName: 'Success Requests' },
      { type: 'bar', xKey: 'date', yKey: 'pending', yName: 'Pending Requests' },
      { type: 'bar', xKey: 'date', yKey: 'failed', yName: 'Failed Requests' },
    ],
  };

  return isLoading ? (
    <StyledContentContainer>
      <LoadingSpinner />
    </StyledContentContainer>
  ) : (
    <StyledContentContainer graybackground='true'>
      <StyledWelcomeMessage>Welcome, {fullNameSplit[0]}</StyledWelcomeMessage>
      {userDetails?.planValidity === 0 ? (
        <StyledPlanExpiryMessage>
          Your plan is expiring today. You can buy a new plan{' '}
          <Link to='plans'>here</Link>
        </StyledPlanExpiryMessage>
      ) : userDetails?.planValidity > 0 ? (
        <StyledPlanExpiryMessage>
          Your existing plan will be expiring in {userDetails?.planValidity}{' '}
          days.
        </StyledPlanExpiryMessage>
      ) : (
        <StyledPlanExpiryMessage>
          Your plan expired {Math.abs(userDetails?.planValidity)} days ago.
        </StyledPlanExpiryMessage>
      )}
      <StyledStatsContainer>
        <StyledStatsBlock>
          <StyledBlockHeader
            backgroundColor={userDetails?.headerBackgroundColor}
          >
            Plan
          </StyledBlockHeader>
          <StyledBlockBody backgroundColor={userDetails?.backgroundColor}>
            {userDetails?.planName}
          </StyledBlockBody>
        </StyledStatsBlock>
        <StyledStatsBlock>
          <StyledBlockHeader backgroundColor={'#5c57e3'}>
            Validity
          </StyledBlockHeader>
          <StyledBlockBody backgroundColor={'#3c34e0'}>
            {moment(userDetails?.packageExpiry).format('DD-MM-YYYY')}
          </StyledBlockBody>
        </StyledStatsBlock>
        <StyledStatsBlock>
          <StyledBlockHeader backgroundColor={'#c9350c'}>
            API Requests
          </StyledBlockHeader>
          <StyledBlockBody backgroundColor={'#862308 '}>
            {userDetails?.requestsAllowed === 0
              ? 'Unlimited'
              : `${userDetails?.requestsUsed}/${userDetails?.requestsAllowed}`}
          </StyledBlockBody>
        </StyledStatsBlock>
        <StyledStatsBlock>
          <StyledBlockHeader backgroundColor={'#b27e00'}>
            Support
          </StyledBlockHeader>
          <StyledBlockBody backgroundColor={'#6b4b00 '}>
            +91 8008897338
          </StyledBlockBody>
        </StyledStatsBlock>
      </StyledStatsContainer>
      <StyledGraphsContainer>
        <StyledGraphContainer>
          <AgCharts options={billFetchData} />
        </StyledGraphContainer>
        <StyledGraphContainer>
          <AgCharts options={billPayData} />
        </StyledGraphContainer>
      </StyledGraphsContainer>
    </StyledContentContainer>
  );
};

export default withAppHOC(Dashboard);
