import { memo } from 'react';
import { StyledHeader } from '../styled';
import AccountSection from '../AccountSection/AccountSection';
import LogoutSection from '../LogoutSection/LogoutSection';

const Header = memo(({ fullName, profilePhoto, onClickLogout }) => {
  return (
    <StyledHeader>
      <AccountSection fullName={fullName} profilePhoto={profilePhoto} />
      <LogoutSection onClickLogout={onClickLogout} />
    </StyledHeader>
  );
});

export default Header;
