import { useEffect, useState } from 'react';
import { widthAuthHOC } from '../../hoc';
import { Heading1 } from '../../components/Headings';
import {
  Form,
  Fieldset,
  PasswordInput,
  OTPInput,
  Button,
  MessageBox,
  MobileInput,
} from '../../components/FormElements';
import Loader from '../../components/Loader/Loader';
import {
  StyledAuthLinks,
  StyledLink,
  StyledButtonAsLink,
  StyledAuthFooterLinkText,
  StyledPara,
  StyledTextHighlight,
} from '../../components/styled';
import API from '../../api';
import { setAuthData, getSearchParams } from '../../utils';
import {
  RESPONSE_STATUSES,
  LOGIN_TYPES,
  OTP_VERIFICATION_TYPES,
} from '../../constants';

const Login = () => {
  const [state, setState] = useState({
    isLoading: false,
    responseStatus: '',
    responseMessage: '',
    routeStage: 'Login',
    loginType: LOGIN_TYPES.PASSWORD,
    mobileNumber: '',
    password: '',
    requestToken: '',
    oneTimePassword: '',
  });

  const changeLoginType = () => {
    setState((prevState) => ({
      ...prevState,
      routeStage: 'Login',
      password: '',
      requestToken: '',
      oneTimePassword: '',
      loginType:
        prevState.loginType === LOGIN_TYPES.PASSWORD
          ? LOGIN_TYPES.OTP
          : LOGIN_TYPES.PASSWORD,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      responseStatus: '',
      responseMessage: '',
    }));
    try {
      const requestData =
        state.routeStage === 'Login'
          ? state.loginType === LOGIN_TYPES.PASSWORD
            ? {
                loginType: state.loginType,
                mobileNumber: state.mobileNumber,
                password: state.password,
              }
            : { loginType: state.loginType, mobileNumber: state.mobileNumber }
          : {
              requestType: OTP_VERIFICATION_TYPES.LOGIN,
              requestToken: state.requestToken,
              mobileNumber: state.mobileNumber,
              oneTimePassword: state.oneTimePassword,
            };

      const response = await API.post(
        state.routeStage === 'Login'
          ? '/authentication/login'
          : '/authentication/verify-otp',
        requestData
      );
      const { status, message, data, token } = response.data;
      if (status === RESPONSE_STATUSES.SUCCESS) {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.SUCCESS,
          responseMessage: message,
        }));
        if (
          state.loginType === LOGIN_TYPES.OTP &&
          state.routeStage === 'Login'
        ) {
          setState((prevState) => ({
            ...prevState,
            requestToken: token,
            routeStage: 'OTP',
          }));
        } else {
          setAuthData(token, data);
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.FAILED,
          responseMessage: message,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        responseStatus: RESPONSE_STATUSES.FAILED,
        responseMessage: error.message,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const resendOTP = async (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      responseStatus: '',
      responseMessage: '',
    }));
    try {
      const response = await API.post('/authentication/resend-otp', {
        requestToken: state.requestToken,
        mobileNumber: state.mobileNumber,
      });
      const { status, message, token } = response.data;
      setState((prevState) => ({
        ...prevState,
        responseStatus: status,
        responseMessage: message,
        requestToken: token,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        responseStatus: RESPONSE_STATUSES.FAILED,
        responseMessage: error.message,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    const errorMessage = getSearchParams('errorMessage');
    if (errorMessage) {
      setState((prevState) => ({
        ...prevState,
        responseStatus: RESPONSE_STATUSES.FAILED,
        responseMessage: errorMessage,
      }));
    }
  }, []);

  return (
    <>
      <Loader isLoading={state.isLoading} />
      <Heading1 textAlign='center'>
        {state.routeStage === 'Login'
          ? `Login to your account`
          : `Verify & Proceed`}
      </Heading1>
      <Form action='#' method='POST' onSubmit={onSubmit}>
        {state.routeStage === 'Login' && (
          <>
            <Fieldset>
              <MobileInput
                value={state.mobileNumber}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    mobileNumber: value,
                  }))
                }
                placeholder='Mobile Number'
                disabled={state.isLoading}
              />
            </Fieldset>
            {state.loginType === LOGIN_TYPES.PASSWORD && (
              <Fieldset>
                <PasswordInput
                  value={state.password}
                  onChange={(value) =>
                    setState((prevState) => ({ ...prevState, password: value }))
                  }
                  placeholder='Password'
                  disabled={state.isLoading}
                />
              </Fieldset>
            )}
          </>
        )}
        {state.routeStage === 'OTP' && (
          <>
            <Fieldset>
              <StyledPara>
                Please enter your OTP sent to{' '}
                <StyledTextHighlight>{state.mobileNumber}</StyledTextHighlight>
              </StyledPara>
            </Fieldset>
            <Fieldset>
              <OTPInput
                value={state.oneTimePassword}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    oneTimePassword: value,
                  }))
                }
                placeholder='OTP'
                disabled={state.isLoading}
              />
            </Fieldset>
          </>
        )}
        <Fieldset>
          <StyledAuthLinks>
            {state.routeStage === 'OTP' && (
              <StyledButtonAsLink type='button' onClick={resendOTP}>
                Resend OTP
              </StyledButtonAsLink>
            )}
            {state.routeStage === 'Login' && (
              <StyledAuthFooterLinkText>
                {state.loginType === LOGIN_TYPES.PASSWORD && (
                  <StyledLink to='/forgot-password' title='Forgot Password'>
                    Forgot Password?
                  </StyledLink>
                )}
              </StyledAuthFooterLinkText>
            )}
            <StyledButtonAsLink
              type='button'
              onClick={changeLoginType}
              title='Forgot Password'
            >
              Login with{' '}
              {state.loginType === LOGIN_TYPES.PASSWORD ? 'OTP' : 'Password'}
            </StyledButtonAsLink>
          </StyledAuthLinks>
        </Fieldset>
        {state.responseStatus && state.responseMessage && (
          <Fieldset>
            <MessageBox
              status={state.responseStatus}
              message={state.responseMessage}
            />
          </Fieldset>
        )}
        <Fieldset>
          <Button disabled={state.isLoading}>
            {state.routeStage === 'Login'
              ? state.loginType === LOGIN_TYPES.PASSWORD
                ? `Login`
                : `Send OTP`
              : `Verify & Proceed`}
          </Button>
        </Fieldset>
      </Form>
      {state.routeStage !== 'OTP' && (
        <StyledAuthFooterLinkText>
          Don't you have an account ?{' '}
          <StyledLink to='/register' title='Register'>
            Register
          </StyledLink>
        </StyledAuthFooterLinkText>
      )}
    </>
  );
};

export default widthAuthHOC(Login);
