const getPlatform = () => {
  return 'Web';
};

const getDeviceType = () => {
  return navigator?.userAgentData?.mobile ? 'Mobile' : 'Desktop';
};

const getOperatingSystem = () => {
  return navigator?.userAgentData?.platform ?? 'Unknown';
};

const shouldRedirect = (dataLength = 0, pageNumber = 1) => {
  return dataLength === 0 && parseInt(pageNumber) !== 1 ? true : false;
};

const redirectToPageOne = () => {
  window.location.href = window.location.href.split('?')?.[0];
};

const getBase64 = (file, cb) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    cb && cb(reader.result);
  };
};

const camelCaseToString = (text) => {
  const result = text?.replace(/([A-Z])/g, ' $1');
  return result?.charAt(0)?.toUpperCase() + result?.slice(1) || '';
};

const getSearchParams = (key) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(key);
};

const formatCurrency = (value) => {
  const rupeeIndian = Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });

  return rupeeIndian.format(value);
};

const setLocalStorage = (key, value) => window.localStorage.setItem(key, value);

const getLocalStorage = (key) => window.localStorage.getItem(key);

const clearLocalStorage = () => window.localStorage.clear();

const getAuthData = () => ({
  isLoggedIn: getLocalStorage('isLoggedIn') === 'true' || false,
  sessionToken: getLocalStorage('sessionToken') || null,
  userProfile: JSON.parse(getLocalStorage('userProfile')) || null,
});

const setAuthData = (sessionToken, userProfile) => {
  setLocalStorage('isLoggedIn', 'true');
  setLocalStorage('sessionToken', sessionToken);
  setLocalStorage('userProfile', JSON.stringify(userProfile));
  const redirectUrl = getSearchParams('redirectUrl');
  if (redirectUrl) {
    window.location.href = redirectUrl;
  } else {
    window.location.href = '/';
  }
};

const validateEmail = (input) => {
  return input?.match(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );
};

const validateMobile = (input) => {
  return input?.match(/^\d{10}$/);
};

const validateOTP = (input, length = 6) => {
  return input?.match(new RegExp(`^\\d{${length}}$`));
};

const groupBy = (array, key) => {
  return array.reduce((result, item) => {
    const keyValue = key(item);
    if (!result[keyValue]) {
      result[keyValue] = [];
    }
    result[keyValue].push(item);
    return result;
  }, {});
}

export {
  getPlatform,
  getDeviceType,
  getOperatingSystem,
  shouldRedirect,
  redirectToPageOne,
  getBase64,
  camelCaseToString,
  getSearchParams,
  formatCurrency,
  setLocalStorage,
  getLocalStorage,
  clearLocalStorage,
  getAuthData,
  setAuthData,
  validateEmail,
  validateMobile,
  validateOTP,
  groupBy,
};
