import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { withAppHOC } from '../../hoc';
import { StyledContentContainer } from '../../components/styled';
import PageHeader from '../../components/PageHeader/PageHeader';
import { MessageBox } from '../../components/FormElements';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../components/NoData/NoData';
import { TableBuilder } from '../../components/TableElements';
import Pagination from '../../components/Pagination/Pagination';
import API from '../../api';
import { RESPONSE_STATUSES } from '../../constants';
import { getSearchParams } from '../../utils';
import { StyledStatusPill } from '../../components/styled';
import { formatCurrency } from '../../utils';

const Orders = () => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 30,
  });

  const readData = async () => {
    try {
      const response = await API.get(
        `/orders?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&searchQuery=`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ['orders', paginationData],
    queryFn: readData,
    cacheTime: 0,
    staleTime: Infinity,
  });

  const renderPageHeading = () => <PageHeader title='Orders' />;

  if (isLoading) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <LoadingSpinner />
        </StyledContentContainer>
      </>
    );
  }

  if (error) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={error.message}
          />
        </StyledContentContainer>
      </>
    );
  }

  return (
    <>
      {renderPageHeading()}
      <StyledContentContainer>
        {data?.status === RESPONSE_STATUSES.FAILED && (
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={data?.message}
          />
        )}
        {data?.data?.length === 0 ? (
          <NoData />
        ) : (
          <>
            <TableBuilder
              isLoading={isLoading}
              tableHeadings={[
                {
                  title: 'Order ID',
                  dataSelector: 'id',
                  dataType: 'number',
                },
                {
                  title: 'Plan',
                  dataSelector: 'planName',
                  dataType: 'string',
                },
                {
                  title: 'Plan Duration',
                  dataSelector: 'duration',
                  dataType: 'string',
                  cellrenderer: (value) => (
                    <>{value === 1 ? `${value} Month` : `${value} Months`}</>
                  ),
                },
                {
                  title: 'Plan Requests',
                  dataSelector: 'requests',
                  dataType: 'string',
                  cellrenderer: (value) => <>{value} Requests</>,
                },
                {
                  title: 'Bill Amount',
                  dataSelector: 'total',
                  dataType: 'string',
                  cellrenderer: (value) => formatCurrency(value),
                },
                {
                  title: 'Payment Status',
                  dataSelector: 'paymentStatus',
                  dataType: 'string',
                  canSort: false,
                  align: 'center',
                  cellrenderer: (value) => (
                    <StyledStatusPill
                      className={
                        value === 'Success'
                          ? 'green'
                          : value === 'Failed'
                          ? 'red'
                          : value === 'Pending'
                          ? 'yellow'
                          : 'blue'
                      }
                    >
                      {value}
                    </StyledStatusPill>
                  ),
                },
                {
                  title: 'Payment ID',
                  dataSelector: 'paymentsId',
                  dataType: 'string',
                  cellrenderer: (value) => (value ? value : 'N/A'),
                },
                {
                  title: 'Created',
                  dataSelector: 'createdOn',
                  dataType: 'string',
                  cellrenderer: (value) =>
                    moment(value).format('Do MMM YYYY, hh:mm:ss A'),
                },
              ]}
              tableData={data?.data?.map((item) => {
                return {
                  ...item,
                  isSelected: 'No',
                };
              })}
            />
            <Pagination
              totalRecords={data?.totalRecords}
              paginationData={paginationData}
              setPaginationData={setPaginationData}
            />
          </>
        )}
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(Orders);
