import { useReducer } from 'react';
import { widthAuthHOC } from '../../hoc';
import { Heading1 } from '../../components/Headings';
import {
  Form,
  Fieldset,
  TextInput,
  MobileInput,
  PasswordInput,
  OTPInput,
  Checkbox,
  Button,
  MessageBox,
} from '../../components/FormElements';
import Loader from '../../components/Loader/Loader';
import {
  StyledAuthLinks,
  StyledLink,
  StyledButtonAsLink,
  StyledAuthFooterLinkText,
  StyledPara,
  StyledTextHighlight,
} from '../../components/styled';
import API from '../../api';
import { setAuthData } from '../../utils';
import { OTP_VERIFICATION_TYPES, RESPONSE_STATUSES } from '../../constants';

const initialState = {
  isLoading: false,
  responseStatus: '',
  responseMessage: '',
  routeStage: 'Register',
  fullName: '',
  mobileNumber: '',
  password: '',
  requestToken: '',
  oneTimePassword: '',
  isCheckedTnC: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FIELD':
      return { ...state, [action.field]: action.value };
    case 'SET_STAGE':
      return { ...state, routeStage: action.stage };
    case 'SET_RESPONSE':
      return {
        ...state,
        responseStatus: action.status,
        responseMessage: action.message,
      };
    case 'SET_LOADING':
      return { ...state, isLoading: action.isLoading };
    default:
      return state;
  }
};

const Register = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onChange = (field, value) => {
    dispatch({ type: 'SET_FIELD', field, value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: 'SET_LOADING', isLoading: true });

    const requestData =
      state.routeStage === 'Register'
        ? {
            fullName: state.fullName,
            mobileNumber: state.mobileNumber,
            password: state.password,
            isCheckedTnC: state.isCheckedTnC,
          }
        : {
            requestType: OTP_VERIFICATION_TYPES.REGISTER,
            requestToken: state.requestToken,
            mobileNumber: state.mobileNumber,
            oneTimePassword: state.oneTimePassword,
          };

    API.post(
      state.routeStage === 'Register'
        ? '/authentication/register'
        : '/authentication/verify-otp',
      requestData
    )
      .then((response) => {
        const { status, message, token, data } = response.data;
        if (status === RESPONSE_STATUSES.SUCCESS) {
          dispatch({
            type: 'SET_RESPONSE',
            status: RESPONSE_STATUSES.SUCCESS,
            message,
          });
          if (state.routeStage === 'Register') {
            dispatch({ type: 'SET_STAGE', stage: 'OTP' });
            dispatch({
              type: 'SET_FIELD',
              field: 'requestToken',
              value: token,
            });
          } else {
            setAuthData(token, data);
          }
        } else {
          dispatch({
            type: 'SET_RESPONSE',
            status: RESPONSE_STATUSES.FAILED,
            message,
          });
          dispatch({
            type: 'SET_FIELD',
            field: 'requestToken',
            value: token,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: 'SET_RESPONSE',
          status: RESPONSE_STATUSES.FAILED,
          message: error.message,
        });
      })
      .finally(() => {
        dispatch({ type: 'SET_LOADING', isLoading: false });
      });
  };

  const resendOTP = (e) => {
    e.preventDefault();
    dispatch({ type: 'SET_LOADING', isLoading: true });
    API.post('/authentication/resend-otp', {
      requestToken: state.requestToken,
      mobileNumber: state.mobileNumber,
    })
      .then((response) => {
        const { status, message, token } = response.data;
        dispatch({ type: 'SET_RESPONSE', status, message });
        dispatch({ type: 'SET_FIELD', field: 'requestToken', value: token });
      })
      .catch((error) => {
        dispatch({
          type: 'SET_RESPONSE',
          status: RESPONSE_STATUSES.FAILED,
          message: error.message,
        });
      })
      .finally(() => {
        dispatch({ type: 'SET_LOADING', isLoading: false });
      });
  };

  const renderStageFields = () => {
    if (state.routeStage === 'Register') {
      return (
        <>
          <Fieldset>
            <TextInput
              value={state.fullName}
              onChange={(value) => onChange('fullName', value)}
              placeholder='Full Name'
              disabled={state.isLoading}
            />
          </Fieldset>
          <Fieldset>
            <MobileInput
              value={state.mobileNumber}
              onChange={(value) => onChange('mobileNumber', value)}
              placeholder='Mobile Number'
              disabled={state.isLoading}
            />
          </Fieldset>
          <Fieldset>
            <PasswordInput
              value={state.password}
              onChange={(value) => onChange('password', value)}
              placeholder='Password'
              disabled={state.isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Checkbox
              value={state.isCheckedTnC}
              onChange={(value) => onChange('isCheckedTnC', value)}
            >
              By creating an account, you agree to our{' '}
              <StyledLink to='/privacy-policy' target='_blank'>
                Privacy Policy
              </StyledLink>
              ,{' '}
              <StyledLink to='/terms-and-conditions' target='_blank'>
                Terms & Conditions
              </StyledLink>
              , and{' '}
              <StyledLink to='/refund-policy' target='_blank'>
                Refund Policy
              </StyledLink>
              .
            </Checkbox>
          </Fieldset>
        </>
      );
    } else if (state.routeStage === 'OTP') {
      return (
        <>
          <Fieldset>
            <StyledPara>
              Please enter your OTP sent to{' '}
              <StyledTextHighlight>{state.mobileNumber}</StyledTextHighlight>
            </StyledPara>
          </Fieldset>
          <Fieldset>
            <OTPInput
              value={state.oneTimePassword}
              onChange={(value) => onChange('oneTimePassword', value)}
              placeholder='OTP'
              disabled={state.isLoading}
            />
          </Fieldset>
        </>
      );
    }
    return null;
  };

  return (
    <>
      <Loader isLoading={state.isLoading} />
      <Heading1 textAlign='center'>
        {state.routeStage === 'Register'
          ? 'Create your account'
          : 'Verify & Proceed'}
      </Heading1>
      <Form action='#' method='POST' onSubmit={onSubmit}>
        {renderStageFields()}
        {state.routeStage === 'OTP' && (
          <Fieldset>
            <StyledAuthLinks>
              <StyledButtonAsLink type='button' onClick={resendOTP}>
                Resend OTP
              </StyledButtonAsLink>
            </StyledAuthLinks>
          </Fieldset>
        )}
        {state.responseStatus && state.responseMessage && (
          <Fieldset>
            <MessageBox
              status={state.responseStatus}
              message={state.responseMessage}
            />
          </Fieldset>
        )}
        <Fieldset>
          <Button disabled={state.isLoading}>
            {state.routeStage === 'Register' ? 'Register' : 'Verify & Proceed'}
          </Button>
        </Fieldset>
      </Form>
      {state.routeStage === 'Register' && (
        <StyledAuthFooterLinkText>
          Already have an account?{' '}
          <StyledLink to='/login' title='Login'>
            Login
          </StyledLink>
        </StyledAuthFooterLinkText>
      )}
    </>
  );
};

export default widthAuthHOC(Register);
