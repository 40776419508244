import { memo, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faCopy,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';

const StyledPasswordContainer = styled.section`
  width: auto;
  height: auto;
  position: relative;
  svg {
    position: absolute;
    top: 14px;
    right: ${({ copy }) => (copy === 'Yes' ? '45px' : '14px')};
    color: #a9a9a9;
    font-size: 20px;
    cursor: pointer;
    &:last-child {
      right: 14px;
    }
    &.active {
      color: forestgreen;
    }
  }
`;

const StyledInput = styled.input`
  background-color: #f9fafb;
  font-size: 14px;
  font-weight: normal;
  color: #000000;
  padding: ${({ copy }) =>
    copy === 'Yes' ? '15px 75px 15px 15px' : '15px 45px 15px 15px'};
  margin: 0px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  width: ${({ copy }) =>
    copy === 'Yes' ? 'calc(100% - 92px)' : 'calc(100% - 62px)'};
  outline: 0px;
  &:focus {
    outline: 1px solid #dbdbdb;
  }
  &:disabled {
    cursor: ${({ copy }) => (copy === 'Yes' ? 'arrow' : 'not-allowed')};
    background-color: ${({ copy }) => (copy === 'Yes' ? '#f9fafb' : '#e1e1e1')};
    border: 1px solid #d3d3d3;
  }
`;

const PasswordInput = memo(
  ({
    value = '',
    onChange = () => {},
    disabled = false,
    copy = false,
    ...rest
  }) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [copied, setCopied] = useState(false);

    const togglePasswordVisibility = useCallback(() => {
      setIsPasswordVisible((prev) => !prev);
    }, []);

    const copyText = async () => {
      await navigator.clipboard.writeText(value);
      setCopied(true);
    };

    useEffect(() => {
      if (copied) {
        setTimeout(() => {
          setCopied(false);
        }, 3000);
      }
    }, [copied]);

    const copyValue = copy ? 'Yes' : 'No';

    return (
      <StyledPasswordContainer copy={copyValue}>
        <StyledInput
          type={isPasswordVisible ? 'text' : 'password'}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          autoComplete='off'
          disabled={disabled}
          copy={copyValue}
          {...rest}
        />
        <FontAwesomeIcon
          icon={isPasswordVisible ? faEye : faEyeSlash}
          onClick={togglePasswordVisibility}
        />
        {copy && (
          <FontAwesomeIcon
            icon={copied ? faCheckCircle : faCopy}
            onClick={copyText}
            className={copied ? 'active' : ''}
          />
        )}
      </StyledPasswordContainer>
    );
  }
);

export default PasswordInput;
