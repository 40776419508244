import styled from 'styled-components';
import JSONPretty from 'react-json-pretty';
import { withAppHOC } from '../../hoc';
import { StyledContentContainer } from '../../components/styled';
import PageHeader from '../../components/PageHeader/PageHeader';
import { TableBuilder } from '../../components/TableElements';
import { API_CONFIG } from '../../constants';

const StyledBodyContainer = styled.section`
  font-size: 14px;
  font-weight: normal;
  color: #000;
  word-break: break-all;
  padding: 0px;
  line-height: 20px;
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  border-radius: 5px;
`;

const StyledHeading = styled.h2`
  font-size: 16px;
  font-weight: normal;
  color: #000;
  background-color: #e5e5e5;
  padding: 15px;
  margin: 0px;
`;

const StyledContent = styled.section`
  padding: 20px;
  @media (max-width: 600px) {
    word-break: break-all;
    overflow-x: scroll;
  }
`;

const StyledApiHeading = styled.h1`
  font-size: 20px;
  font-weight: normal;
  color: #433cf2;
  margin: 0px 0px 20px;
  padding: 0px;
`;

const APIDocumentation = () => {
  const { API_BASE_URL } = API_CONFIG;

  return (
    <>
      <PageHeader title="API Documentation" />
      <StyledContentContainer>
        <StyledApiHeading>Fetch Bill API</StyledApiHeading>
        <StyledBodyContainer>
          <StyledHeading>API Request</StyledHeading>
          <StyledContent>
            <p>
              <b>Method :</b> GET
            </p>
            <p>
              <b>URL :</b>{' '}
              <code>{`${API_BASE_URL}/public/electricity/fetch-bill?api_id=[api_id]&api_token=[api_token]&ref_number=[ref_number]&operator_id=[operator_id]&ca_number=[ca_number]&ca_mobile=[ca_mobile]`}</code>
            </p>
            <TableBuilder
              tableHeadings={[
                {
                  title: 'Field',
                  dataSelector: 'field',
                  dataType: 'string',
                  canSort: false,
                },
                {
                  title: 'Description',
                  dataSelector: 'description',
                  dataType: 'string',
                  canSort: false,
                },
                {
                  title: 'Mandatory/Optional',
                  dataSelector: 'fieldType',
                  dataType: 'string',
                  canSort: false,
                },
                {
                  title: 'Remark',
                  dataSelector: 'remark',
                  dataType: 'string',
                  canSort: false,
                },
              ]}
              tableData={[
                {
                  field: 'api_id',
                  description: 'Api id provided by us',
                  fieldType: 'Mandatory',
                  remark: '',
                },
                {
                  field: 'api_token',
                  description: 'Api token provided by us',
                  fieldType: 'Mandatory',
                  remark: '',
                },
                {
                  field: 'ref_number',
                  description: 'Your request referecne number',
                  fieldType: 'Optional',
                  remark: '',
                },
                {
                  field: 'operator_id',
                  description: 'Our operator id',
                  fieldType: 'Mandatory',
                  remark: '',
                },
                {
                  field: 'ca_number',
                  description: 'Customer account number',
                  fieldType: 'Mandatory',
                  remark: '',
                },
                {
                  field: 'ca_mobile',
                  description: 'Customer mobile number',
                  fieldType: 'Mandatory',
                  remark: '',
                },
              ]}
            />
          </StyledContent>
        </StyledBodyContainer>
        <StyledBodyContainer>
          <StyledHeading>Response</StyledHeading>
          <StyledContent>
            <p>
              <b>Response Type :</b> JSON
            </p>
            <JSONPretty
              id="json-pretty"
              data={JSON.stringify({
                status: 'Success/Failed/Pending',
                message: 'Information',
                data: {
                  customer_name: 'customer name',
                  customer_account_number: 'customer account number',
                  customer_mobile_number: 'customer mobile number',
                  bill_number: 'bill number',
                  bill_date: 'bill date',
                  due_date: 'due date',
                  bill_amount: 'bill amount',
                  late_fee: 'late fee',
                  tax_amount: 'tax amount',
                  total_amount: 'total amount',
                },
              })}
            ></JSONPretty>
          </StyledContent>
        </StyledBodyContainer>
        <StyledApiHeading>Pay Bill API</StyledApiHeading>
        <StyledBodyContainer>
          <StyledHeading>API Request</StyledHeading>
          <StyledContent>
            <p>
              <b>Method :</b> GET
            </p>
            <p>
              <b>URL :</b>{' '}
              <code>{`${API_BASE_URL}/public/electricity/pay-bill??api_id=[api_id]&api_token=[api_token]&ref_number=[ref_number]&operator_id=[operator_id]&ca_number=[ca_number]&ca_mobile=[ca_mobile]&amount=[amount]`}</code>
            </p>
            <TableBuilder
              tableHeadings={[
                {
                  title: 'Field',
                  dataSelector: 'field',
                  dataType: 'string',
                  canSort: false,
                },
                {
                  title: 'Description',
                  dataSelector: 'description',
                  dataType: 'string',
                  canSort: false,
                },
                {
                  title: 'Mandatory/Optional',
                  dataSelector: 'fieldType',
                  dataType: 'string',
                  canSort: false,
                },
                {
                  title: 'Remark',
                  dataSelector: 'remark',
                  dataType: 'string',
                  canSort: false,
                },
              ]}
              tableData={[
                {
                  field: 'api_id',
                  description: 'Api id provided by us',
                  fieldType: 'Mandatory',
                  remark: '',
                },
                {
                  field: 'api_token',
                  description: 'Api token provided by us',
                  fieldType: 'Mandatory',
                  remark: '',
                },
                {
                  field: 'ref_number',
                  description: 'Your request referecne number',
                  fieldType: 'Mandatory',
                  remark: '',
                },
                {
                  field: 'operator_id',
                  description: 'Our operator id',
                  fieldType: 'Mandatory',
                  remark: '',
                },
                {
                  field: 'ca_number',
                  description: 'Customer account number',
                  fieldType: 'Mandatory',
                  remark: '',
                },
                {
                  field: 'ca_mobile',
                  description: 'Customer mobile number',
                  fieldType: 'Mandatory',
                  remark: '',
                },
                {
                  field: 'amount',
                  description: 'Bill total amount',
                  fieldType: 'Mandatory',
                  remark: '',
                },
              ]}
            />
          </StyledContent>
        </StyledBodyContainer>
        <StyledBodyContainer>
          <StyledHeading>Response</StyledHeading>
          <StyledContent>
            <p>
              <b>Response Type :</b> JSON
            </p>
            <JSONPretty
              id="json-pretty"
              data={JSON.stringify({
                status: 'Success/Failed/Pending',
                message: 'Payment Details',
                data: {
                  txn_id: 'CSC  Transaction id',
                  operators_id: 'Operator Transaction id',
                  customer_account_number: 'Customer Bill Number',
                  customer_mobile: 'Customer Mobile Number',
                  amount: 'Bill Amount',
                  payment_time: 'Bill Payment Time',
                },
              })}
            ></JSONPretty>
          </StyledContent>
        </StyledBodyContainer>
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(APIDocumentation);
