import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { withAppHOC } from '../../hoc';
import {
  StyledContentContainer,
  StyledFiltersContainer,
  StyledFilters,
  StyledFilterContainer,
  StyledSearchButton,
  StyledResetButton,
} from '../../components/styled';
import PageHeader from '../../components/PageHeader/PageHeader';
import { MessageBox, SearchInput } from '../../components/FormElements';
import Loader from '../../components/Loader/Loader';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../components/NoData/NoData';
import { TableBuilder } from '../../components/TableElements';
import Pagination from '../../components/Pagination/Pagination';
import API from '../../api';
import { RESPONSE_STATUSES } from '../../constants';
import { getSearchParams } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faX } from '@fortawesome/free-solid-svg-icons';

const APILogs = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 30,
  });

  const readData = async () => {
    try {
      const response = await API.get(
        `/api-logs?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&searchQuery=${searchQuery}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data, isLoading, error, refetch, isRefetching } = useQuery({
    queryKey: ['api_logs', paginationData],
    queryFn: readData,
    cacheTime: 0,
    staleTime: Infinity,
  });

  const reloadData = async () => await refetch();

  const filterData = async (e) => {
    e.preventDefault();
    await refetch();
  };

  const clearFilters = async (e) => {
    e.preventDefault();
    setSearchQuery('');
    refetch();
  };

  const renderPageHeading = () => (
    <PageHeader
      title="API Logs"
      showAddButton={false}
      showReloadData={true}
      reloadData={reloadData}
    />
  );

  if (isLoading) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <LoadingSpinner />
        </StyledContentContainer>
      </>
    );
  }

  if (error) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={error.message}
          />
        </StyledContentContainer>
      </>
    );
  }

  return (
    <>
      <Loader isLoading={isRefetching} />
      {renderPageHeading()}
      <StyledContentContainer>
        <StyledFiltersContainer>
          <StyledFilters>
            <StyledFilterContainer>
              <SearchInput
                value={searchQuery}
                onChange={setSearchQuery}
                placeholder="Search Group"
                disabled={isLoading}
              />
            </StyledFilterContainer>
            <StyledSearchButton onClick={filterData} type="button">
              <FontAwesomeIcon icon={faSearch} />
              Search
            </StyledSearchButton>
            <StyledResetButton onClick={clearFilters} type="button">
              <FontAwesomeIcon icon={faX} />
              Clear
            </StyledResetButton>
          </StyledFilters>
        </StyledFiltersContainer>
        {data?.status === RESPONSE_STATUSES.FAILED && (
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={data?.message}
          />
        )}
        {data?.data?.length === 0 ? (
          <NoData />
        ) : (
          <>
            <TableBuilder
              isLoading={isLoading}
              tableHeadings={[
                {
                  title: 'Date & Time',
                  dataSelector: 'createdOn',
                  dataType: 'string',
                },
                {
                  title: 'Your Ref Number',
                  dataSelector: 'refNumber',
                  dataType: 'number',
                  cellrenderer: (value) => value || 'N/A',
                },
                {
                  title: 'Request Type',
                  dataSelector: 'requestType',
                  dataType: 'string',
                },
                {
                  title: 'Request',
                  dataSelector: 'request',
                  dataType: 'string',
                },
                {
                  title: 'Response',
                  dataSelector: 'response',
                  dataType: 'string',
                },
              ]}
              tableData={data?.data}
            />
            <Pagination
              totalRecords={data?.totalRecords}
              paginationData={paginationData}
              setPaginationData={setPaginationData}
            />
          </>
        )}
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(APILogs);
