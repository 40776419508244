import { memo } from 'react';
import styled from 'styled-components';

const StyledFieldset = styled.fieldset`
  display: block;
  width: auto;
  height: auto;
  margin: 0px 0px 20px;
  padding: 0px;
  border: 0px;
  border-radius: 0px;
  position: relative;
`;

const Fieldset = memo(({ children, ...rest }) => (
  <StyledFieldset {...rest}>{children}</StyledFieldset>
));

export default Fieldset;
