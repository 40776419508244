import { memo } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { formatCurrency } from '../../utils';

const StyledCard = styled.section`
  width: 300px;
  height: auto;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const StyledBody = styled.section`
  flex: 1;
  border-radius: inherit;
`;

const StyledPlanName = styled.h2`
  font-size: 26px;
  font-family: verdana;
  font-weight: normal;
  letter-spacing: 1px;
  text-align: center;
  margin: 0px;
  padding: 15px 0px;
  color: #ffffff;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
`;

const StyledBenefitsText = styled.p`
  font-size: 18px;
  font-weight: normal;
  color: #ffffff;
  margin: 0px;
  padding: 0px 20px 15px 20px;
  font-style: italic;
`;

const StyledPriceText = styled.h3`
  font-size: 45px;
  font-weight: bold;
  color: white;
  text-align: center;
  margin: 0px;
  padding: 20px 0px 35px 0px;
`;

const StyledActualPrice = styled.p`
  font-size: 24px;
  font-weight: normal;
  color: white;
  text-align: center;
  margin: -30px 0px 30px 0px;
  padding: 0px;
  span {
    font-size: 14px;
    font-weight: normal;
    margin: -20px 0px 35px 0px;
    padding: 0px;
    text-align: center;
  }
`;

const StyledFooter = styled.section`
  padding: 20px;
`;

const StyledButton = styled.button`
  font-size: 16px;
  font-weight: normal;
  color: #ffffff;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 5px;
  padding: 15px 20px;
  outline: 0px;
  cursor: pointer;
  border-width: 0px;
  width: 100%;
`;

const SessionCard = memo(
  ({
    planName,
    requests,
    price,
    specialPrice,
    backgroundColor,
    headerBackgroundColor,
  }) => {
    return (
      <StyledCard backgroundColor={backgroundColor}>
        <StyledBody>
          <StyledPlanName backgroundColor={headerBackgroundColor}>
            {planName}
          </StyledPlanName>
          <StyledPriceText>
            {formatCurrency(specialPrice ? specialPrice : price)}
          </StyledPriceText>
          {specialPrice !== 0 && (
            <StyledActualPrice>
              <del>{formatCurrency(price)}</del>{' '}
              <span>
                <FontAwesomeIcon icon={faTag} />{' '}
                {Math.floor(((price - specialPrice) / price) * 100)}% Off
              </span>
            </StyledActualPrice>
          )}
          <StyledBenefitsText>
            {requests === 0 ? 'Unlimited' : requests} API Requests
          </StyledBenefitsText>
          <StyledBenefitsText>Bill Fetch API</StyledBenefitsText>
          <StyledBenefitsText>Pay Bill API</StyledBenefitsText>
          <StyledBenefitsText>24/7 Support</StyledBenefitsText>
        </StyledBody>
        <StyledFooter>
          <StyledButton
            onClick={() => {}}
            backgroundColor={headerBackgroundColor}
          >
            Buy Now
          </StyledButton>
        </StyledFooter>
      </StyledCard>
    );
  }
);

export default SessionCard;
