import { memo, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faDashboard,
  faHeadset,
  faBook,
  faGears,
  faKey,
  faList,
  faCubes,
  faCode,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import Logo from '../Logo/Logo';
import {
  StyledAside,
  StyledAsideHeader,
  StyledAsideLogoContainer,
  StyledAsideTitle,
  StyledAsideTitleText,
  StyledToggleButton,
  StyledAsideBody,
  StyledLinkContainer,
  StyledLinkIcon,
  StyledLinkTextContainer,
  StyledLinkText,
  StyledAsideFooter,
} from '../styled';

const links = [
  { to: '/', icon: faDashboard, text: 'Dashboard', title: 'Dashboard' },
  {
    to: '/plans',
    icon: faCubes,
    text: 'Plans',
    title: 'Plans',
  },
  {
    to: '/orders',
    icon: faList,
    text: 'Orders',
    title: 'Orders',
  },
  {
    to: '/csc-credentials',
    icon: faKey,
    text: 'CSC Credentials',
    title: 'CSC Credentials',
  },
  {
    to: '/api-logs',
    icon: faCode,
    text: 'API Logs',
    title: 'API Logs',
  },
  {
    to: '/api-credentials',
    icon: faKey,
    text: 'API Credentials',
    title: 'API Credentials',
  },
  {
    to: '/api-settings',
    icon: faGears,
    text: 'API Settings',
    title: 'API Settings',
  },
  {
    to: '/operators',
    icon: faUser,
    text: 'Operators',
    title: 'Operators',
  },
  {
    to: '/api-documentation',
    icon: faBook,
    text: 'API Documentation',
    title: 'API Documentation',
    last: true,
  },
];

const Aside = memo(() => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <StyledAside>
      <StyledAsideHeader>
        <StyledAsideLogoContainer>
          <Logo />
        </StyledAsideLogoContainer>
        {isExpanded && (
          <StyledAsideTitle>
            <StyledAsideTitleText>Cybersify</StyledAsideTitleText>
          </StyledAsideTitle>
        )}
        <StyledToggleButton
          onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
        >
          <FontAwesomeIcon icon={isExpanded ? faChevronLeft : faChevronRight} />
        </StyledToggleButton>
      </StyledAsideHeader>
      <StyledAsideBody>
        {links.map((link, index) => (
          <NavLink
            key={index}
            to={link.to}
            title={link.title}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <StyledLinkContainer className={link.last ? 'last-child' : ''}>
              <StyledLinkIcon>
                <FontAwesomeIcon icon={link.icon} />
              </StyledLinkIcon>
              {isExpanded && (
                <StyledLinkTextContainer>
                  <StyledLinkText>{link.text}</StyledLinkText>
                </StyledLinkTextContainer>
              )}
            </StyledLinkContainer>
          </NavLink>
        ))}
      </StyledAsideBody>
      <StyledAsideFooter>
        <Link to="/contact" title="Contact" target="_blank">
          <StyledLinkContainer>
            <StyledLinkIcon>
              <FontAwesomeIcon icon={faHeadset} />
            </StyledLinkIcon>
            {isExpanded && (
              <StyledLinkTextContainer>
                <StyledLinkText>Need Help ?</StyledLinkText>
              </StyledLinkTextContainer>
            )}
          </StyledLinkContainer>
        </Link>
      </StyledAsideFooter>
    </StyledAside>
  );
});

export default Aside;
