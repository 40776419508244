import { memo } from 'react';
import styled from 'styled-components';

const StyledTableFooter = styled.tfoot`
  margin: 0px;
  padding: 0px;
  background-color: #000000;
  color: #ffffff;
  td {
    border-width: 0px;
    :first-child {
      background-color: #545454;
      border-bottom-left-radius: inherit;
    }
    :last-child {
      border-bottom-right-radius: inherit;
    }
  }
`;

const TableFooter = memo(({ children }) => (
  <StyledTableFooter>{children}</StyledTableFooter>
));

export default TableFooter;
