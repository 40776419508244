import { memo, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDownShortWide,
  faArrowUpShortWide,
} from '@fortawesome/free-solid-svg-icons';
import Table from './Table';
import TableHeader from './TableHeader';
import TableHeading from './TableHeading';
import TableBody from './TableBody';
import TableRow from './TableRow';
import TableData from './TableData';
import TableFooter from './TableFooter';
import Loader from '../Loader/Loader';

const StyledTableOuterContainer = styled.section`
  position: relative;
`;

const StyledTableContainer = styled.section`
  width: auto;
  height: auto;
  margin: 0 auto;
  padding: 0px;
  position: relative;
  overflow: auto;
  white-space: nowrap;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ebebeb;
`;

const TableBuilder = memo(
  ({
    tableHeadings = [],
    tableData = [],
    tableFooter = [],
    isLoading = false,
  }) => {
    const [sortList, setSortList] = useState([]);

    const onClickHeader = (dataSelector, dataType) => {
      const newSortList = [...sortList];
      const check = sortList.some((item) => item.dataSelector === dataSelector);
      if (!check) {
        newSortList.push({
          dataSelector,
          dataType,
          sortType: 'ASC',
        });
      } else if (check) {
        const item = sortList.find(
          (item) => item.dataSelector === dataSelector
        );
        if (item.sortType === 'ASC') {
          newSortList[
            sortList.findIndex((item) => item.dataSelector === dataSelector)
          ].sortType = 'DSC';
        } else if (item.sortType === 'DSC') {
          newSortList.splice(
            sortList.findIndex((item) => item.dataSelector === dataSelector),
            1
          );
        }
      }

      setSortList(newSortList);
    };

    const sortTableData = () => {
      if (sortList.length > 0) {
        const newTableData = [...tableData];
        sortList.forEach(({ dataSelector, dataType, sortType }) => {
          if (dataType === 'string') {
            if (sortType === 'DSC') {
              newTableData.sort((item) => item[dataSelector]).reverse();
            } else {
              newTableData.sort((item) => item[dataSelector]);
            }
          } else if (dataType === 'number') {
            if (sortType === 'DSC') {
              newTableData.sort(
                (item1, item2) =>
                  parseFloat(item2[dataSelector]) -
                  parseFloat(item1[dataSelector])
              );
            } else {
              newTableData.sort(
                (item1, item2) =>
                  parseFloat(item1[dataSelector]) -
                  parseFloat(item2[dataSelector])
              );
            }
          } else if (dataType === 'timestamp' || dataType === 'date') {
            if (sortType === 'DSC') {
              newTableData.sort(
                (item1, item2) =>
                  new Date(item2[dataSelector]) - new Date(item1[dataSelector])
              );
            } else {
              newTableData.sort(
                (item1, item2) =>
                  new Date(item1[dataSelector]) - new Date(item2[dataSelector])
              );
            }
          } else if (dataType === 'boolean') {
            if (sortType === 'DSC') {
              newTableData.sort(
                (item1, item2) =>
                  Number(item2[dataSelector]) - Number(item1[dataSelector])
              );
            } else {
              newTableData.sort(
                (item1, item2) =>
                  Number(item1[dataSelector]) - Number(item2[dataSelector])
              );
            }
          }
        });
        return newTableData;
      } else {
        return tableData;
      }
    };

    const composeSortIcon = (dataSelector) => {
      let icon = '';

      if (
        sortList.length > 0 &&
        sortList.some((item) => item.dataSelector === dataSelector)
      ) {
        const { sortType } = sortList.find(
          (item) => item.dataSelector === dataSelector
        );
        if (sortType === 'ASC') {
          icon = <FontAwesomeIcon icon={faArrowDownShortWide} />;
        } else if (sortType === 'DSC') {
          icon = <FontAwesomeIcon icon={faArrowUpShortWide} />;
        }
      }

      return icon;
    };

    const finalTableData = sortList.length > 0 ? sortTableData() : tableData;

    return (
      <StyledTableOuterContainer>
        <Loader showLoader={isLoading} />
        <StyledTableContainer>
          <Table>
            {tableHeadings.length > 0 && (
              <TableHeader>
                <TableRow>
                  {tableHeadings.map(
                    (
                      {
                        title,
                        dataSelector,
                        dataType,
                        canSort = true,
                        requiredRoles = [],
                        customheader = false,
                        CustomHeaderComponent,
                        ...rest
                      },
                      index
                    ) => (
                      <TableHeading
                        key={`th-${index}`}
                        onClick={() =>
                          canSort && onClickHeader(dataSelector, dataType)
                        }
                        {...rest}
                      >
                        {customheader ? (
                          <CustomHeaderComponent />
                        ) : (
                          <>
                            {title} {canSort && composeSortIcon(dataSelector)}
                          </>
                        )}
                      </TableHeading>
                    )
                  )}
                </TableRow>
              </TableHeader>
            )}
            {tableData.length > 0 && (
              <TableBody>
                {finalTableData.map((item, index) => (
                  <TableRow key={`tr-${index}`} isSelected={item?.isSelected}>
                    {tableHeadings.map(
                      (
                        {
                          dataSelector,
                          requiredRoles = [],
                          cellrenderer,
                          ...rest
                        },
                        index
                      ) => (
                        <TableData
                          key={`td-${index}`}
                          {...rest}
                          isSelected={item?.isSelected}
                        >
                          {cellrenderer
                            ? cellrenderer(item[dataSelector], item)
                            : item[dataSelector]}
                        </TableData>
                      )
                    )}
                  </TableRow>
                ))}
              </TableBody>
            )}
            {tableFooter.length > 0 && (
              <TableFooter>
                <TableRow key={`tf-123}`}>
                  {tableFooter.map(({ textAlign, value }, index) => (
                    <TableData textAlign={textAlign} key={`td-${index}`}>
                      {value}
                    </TableData>
                  ))}
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </StyledTableContainer>
      </StyledTableOuterContainer>
    );
  }
);

export default TableBuilder;
