import { useQuery } from '@tanstack/react-query';
import { withAppHOC } from '../../hoc';
import {
  StyledContentContainer,
  StyledPlansOuterContainer,
  StyledPlansContainer,
  StyledPlansHeading,
  StyledSlabText,
} from '../../components/styled';
import { MessageBox } from '../../components/FormElements';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../components/NoData/NoData';
import PlanCard from '../../components/Cards/PlanCard';
import API from '../../api';
import { RESPONSE_STATUSES } from '../../constants';
import { groupBy } from '../../utils';

const Plans = () => {
  const readData = async () => {
    try {
      const response = await API.get(`/plans`);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ['plans'],
    queryFn: readData,
    cacheTime: 0,
    staleTime: Infinity,
  });

  if (isLoading) {
    return (
      <StyledContentContainer>
        <LoadingSpinner />
      </StyledContentContainer>
    );
  }

  if (error) {
    return (
      <StyledContentContainer>
        <MessageBox status={RESPONSE_STATUSES.FAILED} message={error.message} />
      </StyledContentContainer>
    );
  }
  const dataCopy = [...data.data];
  const finalData = groupBy(dataCopy, (item) => item.duration);

  return (
    <StyledContentContainer>
      {data?.status === RESPONSE_STATUSES.FAILED && (
        <MessageBox status={RESPONSE_STATUSES.FAILED} message={data?.message} />
      )}
      {data?.data?.length === 0 ? (
        <NoData />
      ) : (
        <>
          <StyledPlansHeading>Select your Plan</StyledPlansHeading>
          {Object.keys(finalData)?.map((item, index) => {
            return (
              <StyledPlansOuterContainer>
                <StyledSlabText>
                  {parseInt(item) === 1
                    ? `${item} Month Plans`
                    : `${item} Months Plans`}
                </StyledSlabText>
                <StyledPlansContainer>
                  {finalData[item]?.map((item2, index2) => {
                    return (
                      <PlanCard {...item2} key={`plan-${index}-${index2}`} />
                    );
                  })}
                </StyledPlansContainer>
              </StyledPlansOuterContainer>
            );
          })}
        </>
      )}
    </StyledContentContainer>
  );
};

export default withAppHOC(Plans);
