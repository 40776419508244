import {
  AuthBackground,
  AuthContainer,
  AuthSliderContainer,
  AuthWelcomeText,
  AuthTaglineText,
  AuthToolsList,
  AuthToolsListItem,
  AuthToolsText,
  AuthToolsImage,
  AuthFormContainer,
  AuthFormInnerContainer,
  AuthLogoContainer,
  AuthFormBody,
  StyledAppContainer,
  StyledMain,
} from '../components/styled';
import Logo from '../components/Logo/Logo';
import Aside from '../components/Aside/Aside';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import API from '../api';
import { clearLocalStorage } from '../utils';

const widthAuthHOC = (Component) => (props) => {
  return (
    <AuthBackground>
      <AuthContainer>
        <AuthSliderContainer>
          <AuthToolsImage src="./auth-footer.png" alt="Cybersify" />
          <AuthWelcomeText>Welcome</AuthWelcomeText>
          <AuthTaglineText>
            Streamline your workflows with Automation!
          </AuthTaglineText>
          <AuthToolsList>
            <AuthToolsListItem>
              <AuthToolsText>Whatsapp Api</AuthToolsText>
            </AuthToolsListItem>
            <AuthToolsListItem>
              <AuthToolsText>Telegram Api</AuthToolsText>
            </AuthToolsListItem>
            <AuthToolsListItem>
              <AuthToolsText>SMS Api</AuthToolsText>
            </AuthToolsListItem>
            <AuthToolsListItem>
              <AuthToolsText>Payment Gateway Apis</AuthToolsText>
            </AuthToolsListItem>
          </AuthToolsList>
        </AuthSliderContainer>
        <AuthFormContainer>
          <AuthFormInnerContainer>
            <AuthLogoContainer>
              <Logo />
            </AuthLogoContainer>
            <AuthFormBody>
              <Component {...props} />
            </AuthFormBody>
          </AuthFormInnerContainer>
          <Footer />
        </AuthFormContainer>
      </AuthContainer>
    </AuthBackground>
  );
};

const withAppHOC = (Component) => (props) => {
  const onClickLogout = (e) => {
    e.preventDefault();
    API.delete('/authentication/logout');
    clearLocalStorage();
    window.location.href = '/login';
  };

  return (
    <StyledAppContainer>
      <Aside />
      <StyledMain>
        <Header fullName={'My Account'} onClickLogout={onClickLogout} />
        <Component {...props} />
        <Footer />
      </StyledMain>
    </StyledAppContainer>
  );
};

const withPublicHOC = (Component) => (props) => {
  return (
    <>
      <Component {...props} />
    </>
  );
};

export { widthAuthHOC, withAppHOC, withPublicHOC };
