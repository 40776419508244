import { memo, useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { ButtonGroup } from '../FormElements';

const StyledConfirmDialogBoxContainer = styled.section`
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
`;

const StyledOverlay = styled.section`
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
  background-color: rgb(0 0 0 / 50%);
`;

const StyledDialogBoxOuter = styled.section`
  width: auto;
  height: auto;
  background-color: #fff;
  padding: 50px 30px;
  margin: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 25px -20px #000;
  border-radius: 10px;
`;

const StyledDialogBox = styled.section`
  width: 100%;
  max-width: 300px;
  height: auto;
  padding: 0px;
  margin: 0px;
  text-align: center;
  @media (max-width: 600px) {
    width: 100%;
    min-width: 300px;
  }
  h2 {
    font-size: 24px;
    font-weight: bold;
    color: #545454;
    margin: 20px 0px;
    padding: 0px;
    text-transform: capitalize;
  }
  p {
    font-size: 14px;
    font-weight: normal;
    color: #545454;
    margin: 0px 0px 30px 0px;
    padding: 0px;
    line-height: 20px;
  }
`;

const StyledIconHolder = styled.section`
  width: 80px;
  height: 80px;
  margin: 0px auto 20px;
  padding: 0px;
  border: 5px solid ${({ color }) => (color ? color : `#ff4343`)};
  border-radius: 100px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => (color ? color : `#ff4343`)};
  svg {
    font-size: 40px;
  }
`;

const StyledCancelButton = styled.button`
  font-size: 14px;
  font-weight: normal;
  color: #fff;
  border: 0px;
  outline: 0px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: calc(50% - 25px);
  margin: 0px 5px 0px 20px;
  background-color: #545454;
  &:hover {
    background-color: #3e3e3e;
  }
`;

const StyledDeleteButton = styled.button`
  font-size: 14px;
  font-weight: normal;
  color: #fff;
  border: 0px;
  outline: 0px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: calc(50% - 25px);
  margin: 0px 20px 0px 5px;
  background-color: ${({ color }) => (color ? color : `#ff4343`)};
  &:hover {
    background-color: ${({ hovercolor }) =>
      hovercolor ? hovercolor : `#ff0000`};
  }
`;

const ConfirmDialogBox = memo(
  ({
    status = false,
    icon = faTrashCan,
    title = 'Delete ?',
    message = 'Once deleted cannot be recovered, it will permanently delete from database.',
    proceedButtonText = 'Delete',
    cancelButtonText = 'Cancel',
    onClickProceed = () => {},
    onClickCancel = () => {},
    color,
    hoverColor,
  }) => {
    const dialogBoxRef = useRef(null);

    const closeDialogBox = useCallback(
      (e) => {
        if (dialogBoxRef.current && !dialogBoxRef.current.contains(e.target)) {
          onClickCancel();
        }
      },
      [onClickCancel]
    );

    useEffect(() => {
      document.addEventListener('click', closeDialogBox, true);
      return () => {
        document.removeEventListener('click', closeDialogBox, true);
      };
    }, [closeDialogBox]);

    return createPortal(
      status && (
        <StyledConfirmDialogBoxContainer>
          <StyledOverlay>
            <StyledDialogBoxOuter ref={dialogBoxRef}>
              <StyledDialogBox>
                {icon && (
                  <StyledIconHolder color={color}>
                    <FontAwesomeIcon icon={icon} />
                  </StyledIconHolder>
                )}
                {title && <h2>{title}</h2>}
                {message && <p>{message}</p>}
                <ButtonGroup>
                  <StyledCancelButton
                    title={cancelButtonText}
                    onClick={onClickCancel}
                  >
                    {cancelButtonText}
                  </StyledCancelButton>
                  <StyledDeleteButton
                    title={proceedButtonText}
                    onClick={onClickProceed}
                    color={color}
                    hovercolor={hoverColor}
                  >
                    {proceedButtonText}
                  </StyledDeleteButton>
                </ButtonGroup>
              </StyledDialogBox>
            </StyledDialogBoxOuter>
          </StyledOverlay>
        </StyledConfirmDialogBoxContainer>
      ),
      document.getElementById('modals')
    );
  }
);

export default ConfirmDialogBox;
