import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from 'styled-components';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from 'react-router-dom';
import GlobalStyles from './styles';
import { getAuthData } from './utils';
import Register from './screens/Authentication/Register';
import Login from './screens/Authentication/Login';
import ForgotPassword from './screens/Authentication/ForgotPassword';
import Profile from './screens/Account/Profile';
import ChangePassword from './screens/Account/ChangePassword';
import PrivacyPolicy from './screens/PublicPages/PrivacyPolicy';
import TermsAndConditions from './screens/PublicPages/TermsAndConditions';
import RefundPolicy from './screens/PublicPages/RefundPolicy';
import Contact from './screens/PublicPages/Contact';
import Dashboard from './screens/Dashboard/Dashboard';
import Plans from './screens/Plans/Plans';
import Orders from './screens/Orders/Orders';
import CSCCredentials from './screens/CSCCredentials/CSCCredentials';
import APILogs from './screens/APILogs/APILogs';
import APICredentials from './screens/APICredentials/APICredentials';
import APISettings from './screens/APISettings/APISettings';
import APIDocumentation from './screens/APIDocumentation/APIDocumentation';
import Operators from './screens/Operators/Operators';

const queryClient = new QueryClient();

const ProtectedRoutes = () => {
  const { isLoggedIn } = getAuthData();
  return isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to={`/login?redirectUrl=${window.location.href}`} />
  );
};

const AuthRoutes = () => {
  const { isLoggedIn } = getAuthData();
  return isLoggedIn ? <Navigate to="/" /> : <Outlet />;
};

const App = () => {
  const theme = {
    colors: {
      authBackgroundColor: '#ffffff',
      primaryColor: '#3049f8',
      secondaryColor: '#009b59',
      tertiaryColor: '#0067b0',
      tableHeaderColor: '#545454',
    },
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Router>
          <Routes>
            <Route element={<ProtectedRoutes />}>
              <Route exact path="/" element={<Dashboard />} />
              <Route path={'/profile'} element={<Profile />} />
              <Route path={'/change-password'} element={<ChangePassword />} />
              <Route path={'/plans'} element={<Plans />} />
              <Route path={'/orders'} element={<Orders />} />
              <Route path="/csc-credentials" element={<CSCCredentials />} />
              <Route path="/api-logs" element={<APILogs />} />
              <Route path="/api-credentials" element={<APICredentials />} />
              <Route path="/api-settings" element={<APISettings />} />
              <Route path="/api-documentation" element={<APIDocumentation />} />
              <Route path="/operators" element={<Operators />} />
            </Route>
            <Route element={<AuthRoutes />}>
              <Route path={'/register'} element={<Register />} />
              <Route path={'/login'} element={<Login />} />
              <Route path={'/forgot-password'} element={<ForgotPassword />} />
            </Route>
            <Route path={'/privacy-policy'} element={<PrivacyPolicy />} />
            <Route
              path={'/terms-and-conditions'}
              element={<TermsAndConditions />}
            />
            <Route path={'/refund-policy'} element={<RefundPolicy />} />
            <Route path={'/contact'} element={<Contact />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
