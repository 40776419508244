import { useState, useRef } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faClose } from '@fortawesome/free-solid-svg-icons';
import { withAppHOC } from '../../hoc';
import {
  StyledContentContainer,
  StyledMainAreaContainer,
} from '../../components/styled';
import PageHeader from '../../components/PageHeader/PageHeader';
import { Image } from '../../components/Image';
import {
  Form,
  Fieldset,
  Label,
  TextInput,
  MobileInput,
  MessageBox,
  Button,
} from '../../components/FormElements';
import API from '../../api';
import { getBase64, setLocalStorage } from '../../utils';
import { RESPONSE_STATUSES } from '../../constants';

const StyledPhotoContainer = styled.section`
  width: auto;
  height: fit-content;
`;

const StyledPhotoImageContainer = styled.section`
  width: 150px;
  height: 150px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  padding: 10px;
  border: 5px solid
    ${({ theme: { colors } }) => colors.primaryColor || `#3049f8`};
  border-bottom: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const StyledPhotoSelector = styled.section`
  width: auto;
`;

const StyledPhotoButton = styled.button`
  border-width: 0px;
  background-color: ${({ theme: { colors } }) =>
    colors.primaryColor || `#3049f8`};
  padding: 15px 10px;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  &:hover {
    background-color: #0a00ff;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

const StyledFormContainer = styled.section`
  width: 100%;
  max-width: 500px;
  flex: 1;
`;

const StyledInput = styled.input`
  display: none;
`;

const Profile = () => {
  const inputRef = useRef(null);
  const [state, setState] = useState({
    isLoading: false,
    responseStatus: '',
    responseMessage: '',
    fullName: '',
    mobileNumber: '',
    photo: '',
    photoBase64: '',
  });

  const readData = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      responseStatus: '',
      responseMessage: '',
    }));
    try {
      const response = await API.get('/authentication/profile');
      const { status, message, data } = response.data;
      if (status === RESPONSE_STATUSES.SUCCESS) {
        setState((prevState) => ({
          ...prevState,
          responseStatus: '',
          responseMessage: '',
          fullName: data?.fullName,
          mobileNumber: data?.mobileNumber,
          photo: data?.photo,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.FAILED,
          responseMessage: message,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        responseStatus: RESPONSE_STATUSES.FAILED,
        responseMessage: error.message,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      responseStatus: '',
      responseMessage: '',
    }));
    try {
      const response = await API.put('/authentication/profile', {
        fullName: state.fullName,
        mobileNumber: state.mobileNumber,
        photo:
          state.photoBase64 && !isEmpty(state.photoBase64)
            ? state.photoBase64
            : state.photo,
      });
      const { status, message, data } = response.data;
      if (status === RESPONSE_STATUSES.SUCCESS) {
        setLocalStorage('userProfile', JSON.stringify(data));
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.SUCCESS,
          responseMessage: message,
        }));
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            responseStatus: '',
            responseMessage: '',
          }));
        }, 3000);
      } else {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.FAILED,
          responseMessage: message,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        responseStatus: RESPONSE_STATUSES.FAILED,
        responseMessage: error.message,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const openFileSelector = () => {
    inputRef.current.click();
  };

  useState(() => {
    readData();
  }, []);

  return (
    <>
      <PageHeader title='Your Profile' />
      <StyledContentContainer>
        <StyledMainAreaContainer>
          <StyledPhotoContainer>
            <StyledPhotoImageContainer>
              <Image
                source={
                  state.photoBase64
                    ? state.photoBase64
                    : state.photo
                    ? state.photo
                    : 'default-profile-photo.png'
                }
                alt={'Seelam Sivasankar Naidu'}
              />
            </StyledPhotoImageContainer>
            <StyledPhotoSelector>
              <StyledInput
                type='file'
                ref={inputRef}
                onChange={(e) => {
                  getBase64(e.target.files[0], (photoBase64) => {
                    setState((prevState) => ({
                      ...prevState,
                      photoBase64: photoBase64,
                    }));
                  });
                }}
                accept='image/*'
                disabled={state.isLoading}
              />
              <StyledPhotoButton
                disabled={state.isLoading}
                onClick={() => {
                  if (!state.isLoading) {
                    if (!isEmpty(state.photoBase64) || !isEmpty(state.photo)) {
                      inputRef.current.value = null;
                      setState((prevState) => ({
                        ...prevState,
                        photoBase64: '',
                        photo: '',
                      }));
                    } else {
                      openFileSelector();
                    }
                  }
                }}
              >
                {!isEmpty(state.photoBase64) || !isEmpty(state.photo) ? (
                  <>
                    <FontAwesomeIcon icon={faClose} /> Remove Photo
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faCamera} /> Select Photo
                  </>
                )}
              </StyledPhotoButton>
            </StyledPhotoSelector>
          </StyledPhotoContainer>
          <StyledFormContainer>
            <Form action='#' method='POST' onSubmit={onSubmit}>
              <Fieldset>
                <Label required>Full Name</Label>
                <TextInput
                  value={state.fullName}
                  onChange={(value) =>
                    setState((prevState) => ({ ...prevState, fullName: value }))
                  }
                  placeholder='Full Name'
                  disabled={state.isLoading}
                />
              </Fieldset>
              <Fieldset>
                <Label required>Mobile Number</Label>
                <MobileInput
                  value={state.mobileNumber}
                  onChange={(value) =>
                    setState((prevState) => ({
                      ...prevState,
                      mobileNumber: value,
                    }))
                  }
                  placeholder='Mobile Number'
                  disabled={true}
                />
              </Fieldset>
              {state.responseStatus && state.responseMessage && (
                <Fieldset>
                  <MessageBox
                    status={state.responseStatus}
                    message={state.responseMessage}
                  />
                </Fieldset>
              )}
              <Fieldset>
                <Button disabled={state.isLoading}>Save</Button>
              </Fieldset>
            </Form>
          </StyledFormContainer>
        </StyledMainAreaContainer>
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(Profile);
